export function getBodyHeight(): number {
	document.body.style.overflow = 'hidden';

	const t = document.createElement('div');
	t.style.cssText = 'display: block; width:100%; height: 0; visibility: hidden';

	const containerEl = document.body;

	console.log('containerEl', containerEl);

	if (!containerEl) {
		return 0;
	}

	containerEl.appendChild(t);

	const { bottom } = t.getBoundingClientRect();

	const n = window.getComputedStyle(containerEl);

	const height = Math.ceil(
		(['padding-bottom', 'border-bottom-width', 'margin-bottom'] as any[]).reduce(
			(acc, curr) => {
				return acc + parseFloat(n[curr]);
			},
			bottom,
		),
	);

	containerEl.removeChild(t);

	return height;
}

export function postBodyHeightToParentFrame(frameHeight = getBodyHeight()) {
	window.parent.postMessage(
		{
			type: 'resize',
			frameHeight,
		},
		'*',
	);
}

export function isIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return false;
	}
}
